import React from "react";
import "twin.macro";
import { graphql, Link } from "gatsby";
import { PageLayout, CTA, BlogContentBlock } from "../components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Eyewear: React.FC = ({ data: { strapiDsrEyewear } }) => {
  const {
    mainContent,
    brandsContent,
    lensesContent,
    title,
    subtitle,
    intro,
    brands,
  } = strapiDsrEyewear;

  return (
    <PageLayout
      title={title}
      subtitle={subtitle}
      content={mainContent}
      intro={intro}
      flourish={1}
    >
      <div tw="px-4 relative sm:px-6 lg:px-8">
        <div tw="mx-auto prose prose-lg">
          <BlogContentBlock content={brandsContent} />
        </div>
      </div>
      <div tw="max-w-prose mx-auto py-12">
        <div tw="grid grid-cols-2 gap-2 sm:gap-8 md:grid-cols-6 lg:grid-cols-5 mx-2">
          {brands.map(({ description, logo }) => (
            <div
              key={logo.id}
              tw="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 my-5"
            >
              <GatsbyImage
                image={getImage(logo.localFile)}
                alt={description}
                objectFit="contain"
              />
            </div>
          ))}
        </div>
        <div tw="flex justify-center my-8 w-full">
          <Link
            to="/brands"
            tw="bg-dsrTeal-100 border border-transparent font-medium transition-colors duration-200 hover:bg-dsrTeal-200 inline-flex items-center justify-center px-8 py-3 rounded-md text-base text-dsrTeal-700"
          >
            Learn About Brands
          </Link>
        </div>
      </div>
      <div tw="px-4 sm:px-6 lg:px-8 bg-gray-50 overflow-hidden py-16 relative">
        <div tw="mx-auto prose prose-lg">
          <BlogContentBlock content={lensesContent} />
        </div>
        <div tw="flex justify-center my-8 w-full max-w-prose mx-auto">
          <Link
            to="/lenses"
            tw="bg-dsrTeal-100 border border-transparent font-medium transition-colors duration-200 hover:bg-dsrTeal-200 inline-flex items-center justify-center px-8 py-3 rounded-md text-base text-dsrTeal-700"
          >
            Learn About Lenses
          </Link>
        </div>
      </div>
      <CTA />
    </PageLayout>
  );
};

export default Eyewear;

export const query = graphql`
  query eyewearPageQuery {
    strapiDsrEyewear {
      mainContent
      brandsContent
      lensesContent
      subtitle
      intro
      title
      brands {
        description
        logo {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(height: 48)
            }
          }
        }
      }
    }
  }
`;
